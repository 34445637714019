html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 14px;
  height: 100%;
}

#root {
  height: 100%;
}

button:focus {
  outline: none;
}
