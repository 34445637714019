.problem {
  border: 1px solid grey;
  margin-bottom: 3px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.problem .firstRow {
  padding: 2px 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  min-height: 34px;
}

.problem .secondRow {
  border-radius: 5px;
  position: absolute;
  height: 100%;
  right: 2px;
  top: 0;
  display: flex;
  transition: width 0.2s;
  overflow: hidden;
  width: 0;
}

.problem .secondRow.expanded {
  display: flex;
  width: 120px;
}

.problem .secondRow.expandedLarge {
  display: flex;
  width: 180px;
}

.problem .secondRow .selector {
  margin-top: 2px;
  margin-bottom: 2px;
  flex-grow: 1;
}

.problem .selector {
  flex-basis: 0;
}

.problem .selector .buttonText {
  font-size: 8px;
  margin-top: -3px;
}

.problem .id {
  width: 20px;
  font-size: 16px;
}

.problem .nameAndDescription {
  text-align: left;
  margin-left: 15px;
  margin-right: auto;
  font-size: 1rem;
}

.problem .description {
  font-size: 0.75rem;
}

.problem .points {
  text-align: right;
  font-size: 28px;
}