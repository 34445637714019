.scoreboardList {
  flex-grow: 1;
  flex-basis: 0;
  flex-direction: column;
  display: flex;
  min-width: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 370px;
}

.separator {
  width: 2px;
  background: black;
}

.scoreboardList .scoreboardListContenders {
  flex-basis: 0;
  flex-grow: 1;
}

.scoreboardList .scoreboardListContendersPaging {
  overflow: hidden;
}

.scoreboardList .scoreboardListPaging {
  display: flex;
  padding: 5px;
}

.scoreboardList .scoreboardListPaging .paging:first-child {
  margin-left: auto;
}

@keyframes splash {
  0% {
    color: inherit;
    background-color: inherit;
  }

  10% {
    color: #888;
    background-color: white;
  }

  90% {
    color: #888;
    background-color: white;
  }

  100% {
    color: inherit;
    background-color: inherit;
  }
}

.highlight {
  animation: splash 3s normal forwards ease-in-out;
  z-index: 10;
}

.scoreboardList .scoreboardListPaging .paging {
  margin-left: 2px;
  margin-right: 2px;
  border-radius: 5px;
  width: 5px;
  height: 5px;
  background: #555;
}

.scoreboardList .scoreboardListPaging .paging.current {
  background: rgb(184, 184, 184);
}

.scoreboardList .scoreboardListPaging .paging:last-child {
  margin-right: auto;
}

.compClassHeader {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.contenderRow {
  display: flex;
  width: 100%;
  background: black;
}

.compClassHeader .compClass {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: bolder;
}

.compClassHeader .status {
  color: #888;
  font-size: 18px;
  margin-top: 15px;
}

@-webkit-keyframes blinker {
  0% {
    opacity: 0.1;
  }

  25% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0.1;
  }
}

.compClassHeader .time {
  margin-top: 1px;
  font-size: 24px;
  font-family: monospace;
}

.compClassHeader .time.active {
  margin-top: 0px;
  font-size: 28px;
}

.name {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
}

.position {
  margin-left: 20px;
  width: 30px;
}

.score {
  margin-left: 10px;
  width: 50px;
}