.mainView {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: white;
}

.titleRow {
  display: flex;
  align-items: baseline;
}

.titleRow .name {
  margin-right: auto;
}

.titleRow button {
  margin-left: 10px;
}

.pointsRow {
  display: flex;
  align-items: baseline;
}

.pointsDesc.total {
  margin-right: auto;
}

.pointsDesc {
  padding: 0 5px;
  font-size: 10px;
}

.pointsRow .points {
  font-size: 40px;
}

.headerRow {
  display: flex;
  align-items: baseline;
  margin-left: -10px;
  margin-right: -10px;
  padding: 0 10px;
  margin-bottom: 3px;
  flex-shrink: 0;
}

.headerRow.showSmall {
  margin: 0 20px;
}

.headerRow .selectorWrapper {
  flex-grow: 1;
  flex-basis: 0;
}

.scoreboardLink {
  padding: 0.5rem 0;
}