.startView {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-image: url("/src/assets/background.jpg");
  background-size: cover;
  padding: 0 !important;
  position: relative;
}

.startView .message {
  font-weight: normal;
}

.startView .activationWrapper {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 80px 50px 0 50px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 180px;
  color: white;
}

.startView input {
  color: white;
  border-bottom: 1px solid white;
  margin-bottom: 20px;
  background: none;
  font-size: 36px;
  margin-left: 50px;
  margin-right: 50px;
}

.startView button.disabled {
  display: none;
}
